import i18n from "i18next";
import { initReactI18next } from "react-i18next";


const resources = {
};

i18n.use(initReactI18next).init({
    resources,
    lng: "de",
    keySeparator: false,
    interpolation: {
        escapeValue: false,
    },
    debug: false,
    appendNamespaceToCIMode: true,
    joinArrays: false,
    returnObjects: false,
	parseMissingKeyHandler(key) {
    	// console.log("missing translation: ", key);
    	// return "missing_"+key;
    	return key;
	},
	react: {
        wait: false,
        nsMode: "default",
        useSuspense: false,
    },
});

export default i18n;
