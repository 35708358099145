import axios from "axios";
export const BaseURL = 'https://api.finphase.m8werk.de/';

export function PostData(type, data, files = false, id = "") {
    const formData = new FormData();
    if (files) {
        Object.keys(files).map(key => {
            formData.append(id + "-" + key, files[key]);
            return true;
        })
    }
    const requestData = files ? formData : JSON.stringify(data);
    const requestOptions = {
        withCredentials: true
    };
    return new Promise((resolve, reject) => {
        axios.post(BaseURL + type, requestData, requestOptions)
            .then((response) => {
            	if (response.status !== 200) {
                    const error = "HTML ERROR " + response.status + ": " + codes[response.status];
                    return {"error": {"text": error}}
                }
                return response.data
            })
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export function validateAddress(data) {
    const formdata = new FormData();
    formdata.append("APIKey", "av-9ec45793bbd63a4ff047bf593f7798fd");
    formdata.append("StreetAddress", data.strasse + " " + data.hausnummer);
    formdata.append("City", data.ort);
    formdata.append("PostalCode", data.plz);
    formdata.append("Country", data.land);

    const requestOptions = {
        method: 'POST', body: formdata, redirect: 'follow'
    };

    return new Promise((resolve, reject) => {
        fetch("https://api.address-validator.net/api/verify", requestOptions)
            .then((response) => {
                // console.log("123 response: ",response);
                return response.json()
            })
            .then((res) => {
                // console.log("123 res: ",res);
                resolve(res);
            })
            .catch((error) => {
                // console.log("123 error: ",error);
                reject(error);
            });
    });
}


const codes = {
    202: "Accepted",
    502: "Bad Gateway",
    400: "Bad Request",
    409: "Conflict",
    100: "Continue",
    201: "Created",
    417: "Expectation Failed",
    424: "Failed Dependency",
    403: "Forbidden",
    504: "Gateway Timeout",
    410: "Gone",
    505: "HTTP Version Not Supported",
    418: "I'm a teapot",
    419: "Insufficient Space on Resource",
    507: "Insufficient Storage",
    500: "Internal Server Error",
    411: "Length Required",
    423: "Locked",
    420: "Method Failure", "isDeprecated": true,
    405: "Method Not Allowed",
    301: "Moved Permanently",
    302: "Moved Temporarily",
    207: "Multi-Status",
    300: "Multiple Choices",
    511: "Network Authentication Required",
    204: "No Content",
    203: "Non Authoritative Information",
    406: "Not Acceptable",
    404: "Not Found",
    501: "Not Implemented",
    304: "Not Modified",
    200: "OK",
    206: "Partial Content",
    402: "Payment Required",
    308: "Permanent Redirect",
    412: "Precondition Failed",
    428: "Precondition Required",
    102: "Processing",
    407: "Proxy Authentication Required",
    431: "Request Header Fields Too Large",
    408: "Request Timeout",
    413: "Request Entity Too Large",
    414: "Request-URI Too Long",
    416: "Requested Range Not Satisfiable",
    205: "Reset Content",
    303: "See Other",
    503: "Service Unavailable",
    101: "Switching Protocols",
    307: "Temporary Redirect",
    429: "Too Many Requests",
    401: "Unauthorized",
    451: "Unavailable For Legal Reasons",
    422: "Unprocessable Entity",
    415: "Unsupported Media Type",
    305: "Use Proxy",
}
